<template>
    <CompModal ref="comp_modal" :title="isEdit ? '修改议题内容' : '发起议事'" width="450px">
        <CompForm :forms="forms" :value="formdata" @on-submit="onSubmit"></CompForm>
    </CompModal>
</template>

<script>
import Request from "../../jointly/utils/request"

import CompForm from "../../customform/components/CompForm.vue"
import People from "./People"
import CompModal from "../../residentdatabase/components/CompModal.vue"

export default {
    components: {
        CompForm,
        CompModal,
    },

    data() {
        return {
            formdata: null,
            // 议题类型
            issueType: [],
            // 是否为编辑模式
            isEdit: false,
            // 表单结构
            forms: null,
        }
    },

    methods: {
        display(id, data) {
            this.id = id

            if (!this.forms) {
                this.getIssueType()
            }

            this.isEdit = !!id

            this.$refs.comp_modal.display()

            if (data) {
                var photo

                if (data.photos) {
                    photo = []

                    for (let i = 0, ps = data.photos; i < ps.length; i++) {
                        photo.push({
                            url: ps[i],
                        })
                    }
                }

                this.issueId = data.issueId

                this.formdata = {
                    title: data.title,
                    content: data.content,
                    photo: photo,
                }
            } else if (id) {
                // 获取议事详情
                this.getDetail(id)
            }
        },

        getIssueType() {
            Request.get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "ISSUE_TYPE",
                appCode: "",
            }).then(res => {
                this.setForms(res)
            })
        },

        setForms(issueTypes) {
            this.forms = [
                {
                    type: "text",
                    title: "基本信息",
                },
                {
                    type: "input",
                    title: "标题",
                    name: "title",
                    required: true,
                },
                {
                    type: "select",
                    title: "议题类型",
                    name: "issueType",
                    required: true,
                    options: issueTypes.map(v => ({
                        value: v.dictKey,
                        label: v.dictValue,
                    })),
                },
                {
                    type: "textarea",
                    title: "内容",
                    name: "content",
                    required: true,
                    contenteditable: true,
                    height: "500px",
                },
                {
                    type: "text",
                    title: "资源文件",
                },
                {
                    type: "upload",
                    title: "图片",
                    name: "photo",
                    max: 9,
                },
                {
                    type: "text",
                    title: "参与人员",
                },
                {
                    type: "input",
                    title: "选择参与人员",
                    name: "participating",
                    component: People,
                    required: true,
                },
                {
                    type: "input",
                    title: "选择列席人员",
                    name: "attendance",
                    component: People,
                },
                {
                    type: "input",
                    title: "选择主持人员",
                    name: "host",
                    component: People,
                },
                {
                    type: "input",
                    title: "选择审核人员",
                    name: "examine",
                    component: People,
                },
                {
                    type: "text",
                    title: "身份认证",
                },
                {
                    type: "switch",
                    title: "是否需要身份认证",
                    name: "authentication",
                },
            ]
        },

        getDetail(id) {
            Request.get("/gateway/syinfopublish/api/app/chamber/getIssueUserInfo", {
                issueId: id,
            }).then(res => {
                res.authentication = res.authentication === "1"

                const names = { 100: "participating", 101: "attendance", 102: "host", 103: "examine" }
                const types = ["orgCodeList", "groupList", "userList"]

                // 获取参与人用户信息
                for (let i = 0, ul = res.chamberUserList; i < ul?.length; i++) {
                    let v = ul[i],
                        u = JSON.parse(v.userJson)

                    var pti

                    // 查找已选用户类型
                    for (let ti = 0; ti < types.length; ti++) {
                        if (u[types[ti]]) {
                            pti = ti
                        }
                    }

                    // 获取用户列表
                    let us = u[types[pti]]

                    let cfn = {
                        peopleType: pti,
                        value: us,
                    }

                    res[names[v.userType]] = cfn
                }

                this.formdata = res
            })
        },

        onChangePhoto(v) {
            this.$set(this.formdata, "photo", v)
        },

        async onSubmit(evt) {
            const value = JSON.parse(JSON.stringify(evt.value))

            const chamberUserList = []

            ;["participating", "attendance", "host", "examine"].forEach(v => {
                if (value[v]) {
                    chamberUserList.push(value[v])
                    delete value[v]
                }
            })

            if (value.photo && value.photo instanceof Array) {
                value.photo = JSON.stringify(value.photo)
            }

            value.issueRuleVoList = [
                {
                    ruleCode: "authentication",
                    ruleValue: value.authentication ? "1" : "0",
                },
            ]

            Request.post(
                `/gateway/syinfopublish/api/pc/chamber/${this.isEdit ? "updateChamberIssue" : "createChamberIssue"}`,
                {
                    ...value,
                    chamberUserList,
                    createBy: parent.vue.loginInfo.userinfo.userName,
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    orgCode: parent.vue.loginInfo.userinfo.communityCode,
                    adviceId: this.issueId,
                    id: this.id,
                    channel: 0,
                },
                {
                    json: true,
                }
            ).then(() => {
                this.$Message.success("提交成功")
                this.$refs.comp_modal.close()
                this.$emit("on-submit", {
                    tag: "Publish",
                })
            })
        },

        /**
         * 改变议题类型选择器事件
         */
        onChangeIssueType(evt) {
            this.$set(this.formdata, "issueType", this.issueType[evt.detail.value].dictKey)
        },

        /**
         * 根据议题类型 ID 获取名称
         */
        getIssueTypeName(id) {
            for (let i = 0, its = this.issueType; i < its.length; i++) {
                let v = its[i]

                if (id === v.dictKey) {
                    return v.dictValue
                }
            }
        },

        onChangeValue(name, evt) {
            this.$set(this.formdata, name, evt.detail.value)
        },
    },
}
</script>

<style lang="less">
.agendaroom-management {
    .form-box {
        padding: 0 20px 75px 20px;
        display: flex;
        flex-wrap: wrap;

        .title {
            position: relative;
            font-size: 13px;
            color: #888;
            flex-shrink: 0;
            margin-bottom: 7px;

            .required {
                position: absolute;
                top: 0;
                right: 5px;
            }
        }

        .item-box {
            padding: 7px 0;
            width: 33.33%;

            &:first-child {
                padding-top: 15px;
            }

            .picker {
                flex-grow: 1;
            }

            .input-box {
                position: relative;
                width: 100%;

                input {
                    height: 100%;
                }

                .input {
                    position: relative;
                    height: 45px;
                    width: 100%;
                    padding: 0 50px 0 12px;
                    z-index: 10;
                    box-sizing: border-box;
                    border-radius: 6px;
                    transition: all 0.3s ease;
                    background: #fff;
                    font-size: 14px;
                    border: 1px solid #e3e3e3;
                    display: flex;
                    align-items: center;

                    &:hover,
                    &:focus {
                        border-color: #b3b3b3;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    }

                    input {
                        font-size: 14px;
                    }

                    .placeholder {
                        color: #888;
                    }
                }

                .icon-box {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 15;

                    .icon {
                        font-size: 20px;
                    }
                }
            }

            .picker-box {
                position: relative;
                background: #fff;
                border-radius: 6px;
                border: 1px solid #e3e3e3;

                .picker {
                    position: relative;
                    z-index: 40;
                }

                .value-box {
                    position: relative;
                    height: 45px;
                    width: 100%;
                    padding: 0 50px 0 12px;
                    z-index: 10;
                    box-sizing: border-box;
                    transition: all 0.3s ease;
                    display: flex;
                    align-items: center;

                    .value {
                        color: #000;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .holder {
                        color: #888;
                        font-size: 14px;
                    }
                }

                .icon-box {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 8;
                    color: #666;
                }
            }

            .edit-box {
                position: relative;
                padding: 12px;
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                min-height: 150px;
                z-index: 10;
                outline: none;
                resize: none;
                font-size: 14px;
                border: 1px solid #e3e3e3;
                border-radius: 6px;
                transition: all 0.3s ease;
                box-sizing: border-box;
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 350px;

                &:hover,
                &:focus {
                    border-color: #b3b3b3;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
            }

            &.row {
                display: flex;
                align-items: center;

                .title {
                    margin: 0 5px 0 0;
                    padding-right: 12px;
                }
            }
        }

        .agreement {
            padding: 7px 0;
            display: flex;
            align-items: center;

            .name {
                margin-left: 7px;
                font-size: 13px;
                line-height: 1;
            }
        }
    }

    .submit-btn {
        position: fixed;
        bottom: 15px;
        left: 15px;
        right: 15px;
        height: 45px;
        background: #14b16e;
        color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        margin-top: 15px;
        width: auto;
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
            border: initial;
        }
    }
}
</style>
