<template>
    <div class="elem-tree-box" :key="uuid + config.key">
        <div class="elem-tree-item" v-for="(item, idx) in items" :key="idx" @click.stop="item.childs && onExpandChilds(item, idx)">
            <div class="row-box" :class="{ viscosity: level === 1 }">
                <div class="selection" :class="item.selection ? item.selection : ''" @click.stop="onSelection(item, idx)">
                    <div class="icon-box" v-if="item.selection === 'all'">
                        <Icon type="ios-checkmark" />
                    </div>
                </div>
                <div class="title-box" @click.stop="onSelection(item, idx)">{{ item.value }}{{ item.explanation ? "（" + item.explanation + "）" : "" }}</div>
                <div class="down-icon">
                    <div v-if="item.childs" class="icon-box" :class="{ expand: item.expand }">
                        <Icon type="ios-arrow-forward" />
                    </div>
                </div>
            </div>
            <div class="childs-box" :class="{ expand: item.expand }">
                <ElemTree v-if="item.childs" :items="item.childs" :prototype="prototypes" :selected="selected" :value="value" :config="config" :level="level + 1"></ElemTree>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ElemTree",

    data() {
        return {
            uuid: this.$core.randomString(),
            prototypes: null,
        }
    },

    props: {
        // 当前节点列表
        items: Array,
        // 数据原型
        prototype: {
            type: Array,
            default() {
                return this.items
            },
        },
        // 值
        value: {
            type: Array,
            default() {
                return []
            },
        },
        // 已选数组
        selected: {
            type: Array,
            default() {
                return []
            },
        },
        // 配置数据
        config: {
            type: Object,
            default() {
                return {
                    key: this.$core.randomString(),
                }
            },
        },
        // 树层级
        level: {
            type: Number,
            default: 1,
        },
    },

    watch: {
        "config.key"(v) {
            this.key = v
        },

        items(v, o) {
            if (v === o) return

            if (this.level === 1) {
                this.prototypes = v
            }

            if (this.value) {
                this.processValue(this.value)
            }
        },

        selected(v) {
            this.$emit("on-change", {
                tag: "ElemTree",
                value: v,
            })
        },

        prototype: {
            handler(v) {
                this.prototypes = v
            },
            immediate: true,
        },

        value: {
            handler(v) {
                v && this.processValue(v)
            },
        },
    },

    methods: {
        /**
         * 展开子节点
         */
        onExpandChilds(item, idx, flag = null) {
            item.expand = flag != null ? flag : !item.expand
            this.$set(this.items, idx, item)
        },

        /**
         * 点击选择框
         */
        onSelection(item, idx, selected = false) {
            const load = this.$Message.loading("加载中...")

            setTimeout(() => {
                if (selected) {
                    item.selection = false
                }

                if (item.selection) {
                    this.traverse(this.prototypes, item.id, false)
                } else {
                    this.traverse(this.prototypes, item.id, true)
                }

                // 是否为选中，且存在子节点，则展开子节点
                if ((!idx || idx === 0) && item.selection && item.childs) {
                    this.onExpandChilds(item, idx, true)
                }

                this.$set(this.config, "key", this.$core.randomString())

                load()
            }, 10)
        },

        /**
         * 遍历
         */
        traverse(list, id, isSelect, fathers = [], cb) {
            for (let i = 0; i < list.length; i++) {
                var v = list[i]

                if (v.id === id) {
                    cb && cb()

                    if (v.childs) {
                        v.selection = isSelect ? "all" : null
                        // 处理父节点
                        this.processFathers(list, isSelect, fathers)
                        // 遍历所有子节点
                        this.allSelection(v.childs, isSelect)
                    } else {
                        v.selection = isSelect ? "all" : null
                        // 处理已选节点
                        this.processSelected(v, isSelect)
                        // 处理父节点
                        this.processFathers(list, isSelect, fathers)
                    }

                    break
                }

                if (v.childs) {
                    // 遍历子节点
                    this.traverse(v.childs, id, isSelect, fathers, () => {
                        // 触发回调函数
                        cb && cb()
                        // Push 父节点
                        fathers.push(v)
                    })
                }
            }
        },

        /**
         * 处理父节点
         */
        processFathers(list, isSelect, fathers) {
            if (isSelect) {
                for (let f_i = 0; f_i < fathers.length; f_i++) {
                    const f = fathers[f_i]
                    f.selection = isSelect ? "all" : null
                }
            } else {
                for (let i = 0; i < list.length; i++) {
                    if (list[i].selection) {
                        return
                    }
                }

                var father = fathers[fathers.length - 1]
                if (!father) return
                // 父节点勾选取消
                father.selection = null
                // 移除最后一个父节点
                fathers.splice(fathers.length - 1, 1)
                // 获取父节点列表
                var ls = this.getFathers(this.prototype, father)
                if (!ls) return
                // 处理父节点
                this.processFathers(list, isSelect, fathers)
            }
        },

        /**
         * 获取父节点列表
         */
        getFathers(list, father, f_l) {
            for (let i = 0; i < list.length; i++) {
                const v = list[i]

                if (v.id === father.id) {
                    return f_l
                }

                if (v.childs) {
                    return this.getFathers(v.childs, father, list)
                }
            }
        },

        /**
         * 处理已选节点
         */
        processSelected(item, isSelect) {
            if (isSelect) {
                for (let i = 0, l = this.selected; i < l.length; i++) {
                    if (l[i].id === item.id) return
                }

                if (item.childs) {
                    return
                }

                this.selected.push(item)
            } else {
                for (let i = 0, l = this.selected; i < l.length; i++) {
                    if (l[i].id === item.id) {
                        return this.selected.splice(i, 1)
                    }
                }
            }
        },

        /**
         * 遍历所有子节点
         */
        allSelection(list, isSelect) {
            for (let i = 0; i < list.length; i++) {
                var v = list[i]

                v.selection = isSelect ? "all" : null
                this.processSelected(v, isSelect)

                if (v.childs) {
                    this.allSelection(v.childs, isSelect)
                }
            }
        },

        /**
         * 处理值
         */
        processValue(list) {
            for (let i = 0, l = this.items; i < l.length; i++) {
                var v = l[i]

                if (~list.indexOf(v.id) && this.selected.indexOf(v) <= -1) {
                    this.onSelection(v, null, true)
                }
            }
        },
    },
}
</script>

<style lang="less">
.elem-tree-box {
    .elem-tree-item {
        .row-box {
            display: flex;
            align-items: center;
            font-size: 14px;
            padding: 10px 0;

            .selection {
                margin: 0 10px;
                width: 20px;
                height: 20px;
                border: 1px solid #e3e3e3;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;

                .icon-box {
                    font-size: 20px;
                    color: #fff;
                }

                &.all {
                    background: #2db7f5;
                    border-color: #2db7f5;
                }
            }

            .title-box {
                flex-grow: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .down-icon {
                width: 15px;
                margin: 0 10px;
                display: flex;
                flex-shrink: 0;

                .icon-box {
                    &.expand {
                        transform: rotate(90deg);
                    }
                }
            }

            &.viscosity {
                position: sticky;
                top: 0;
                background: #fff;
                z-index: 10;
                box-shadow: 0 10px 10px rgba(255, 255, 255, 0.8);
            }
        }

        .childs-box {
            margin-left: 15px;
            height: 0;
            overflow: hidden;
            transition: height 0.2s;

            &.expand {
                height: initial;
            }
        }
    }
}
</style>
