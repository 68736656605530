<template>
    <div class="agendaroom-index">
        <CompContent :title="menuName" :error="error">
            <div class="content">
                <div class="online" @scroll="onScroll">
                    <div class="title">
                        <span>线上议事</span>
                        <div class="btn" @click="onDisplayRule">给居民的一封信</div>
                    </div>
                    <div class="tools">
                        <div class="btn" @click="$refs.publish.display()">
                            发起新议事
                            <div class="icon liefeng-icon liefeng-icon-add-bold"></div>
                        </div>
                        <div class="btn column" @click="route.jump('/agendaroomopinion')">
                            <div class="value">{{ counts.adviceCount || 0 }}</div>
                            <div class="name">意见收集</div>
                        </div>
                        <div class="btn column" @click="route.jump('/agendaroomcomment')">
                            <div class="value">{{ counts.commonsCount || 0 }}</div>
                            <div class="name">评论审核</div>
                        </div>
                        <div class="btn column" @click="route.jump('/agendaroomfinish')">
                            <div class="value">{{ counts.issueCount || 0 }}</div>
                            <div class="name">已归档</div>
                        </div>
                    </div>
                    <div class="scroll-view">
                        <div class="agenda-item" v-for="(item, idx) in list" :key="'agenda' + idx">
                            <div class="title">{{ item.title }}</div>
                            <div class="timer">公开时间：{{ $core.formatDate(new Date(item.gmtCreate), "yyyy/MM/dd") }}</div>
                            <div class="timer">当前环节：{{ getFlowName(item) }}</div>
                            <!-- <div class="timer">公开时间：{{ $core.formatDate(new Date(item.gmtCreate), "yyyy/MM/dd") }}</div> -->
                            <div class="tool">
                                <div class="right-btn" @click="route.jump('/agendaroomevolve', { id: item.id })">详细进展</div>
                            </div>
                        </div>
                        <div class="empty-box" v-if="!list || list.length <= 0">
                            <image class="img" src="../../../images/empty_1.png" />
                            <div class="name">暂无数据</div>
                        </div>
                    </div>
                </div>

                <div class="offline">
                    <div class="title">线下议事</div>
                    <div class="record" @click="route.jump('/agendaroomoffline')">线下议事记录</div>
                    <div class="title">成果展示</div>
                    <div class="achievement-box">
                        <div class="title">议事开展数</div>
                        <div class="col" v-for="(item, idx) in statisticsModule" :key="idx">
                            <div class="row" v-for="(v, i) in item" :key="i">
                                <div class="name">{{ v.t }}</div>
                                <div class="value">{{ (typeof v.k === "function" ? v.k(statisticsResult) : statisticsResult[v.k]) || 0 }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-box">
                        <div class="item" :class="{ ac: ac_date === 'all' }" @click="ac_date = 'all'">全部</div>
                        <div class="item" :class="{ ac: ac_date === 'year' }" @click="ac_date = 'year'">本年</div>
                        <div class="item" :class="{ ac: ac_date === 'quarter' }" @click="ac_date = 'quarter'">本季度</div>
                        <div class="item" :class="{ ac: ac_date === 'month' }" @click="ac_date = 'month'">本月</div>
                    </div>
                    <div class="chart-base">
                        <div class="chart-box">
                            <ElemChart :option="chart_opt"></ElemChart>
                        </div>
                        <div class="table-box">
                            <ElemTable isIndex :columns="table_columns" :dataList="ac_group" height="100%"></ElemTable>
                        </div>
                    </div>
                </div>
            </div>
        </CompContent>

        <CompForm class="rule-form" ref="comp_form" :forms="rule_forms" detailApi="/gateway/syinfopublish/api/pc/chamber/getConfigByOrgCode" submitApi="/gateway/syinfopublish/api/pc/chamber/createOrUpdate" idKey="orgCode"></CompForm>
        <CompForm ref="offline_form" :forms="offline_forms" submitApi="/gateway/syinfopublish/api/pc/chamber/createOrUpdate"></CompForm>

        <Publish ref="publish" @on-submit="onReset"></Publish>
    </div>
</template>

<script>
import CompContent from "../customform/components/CompContent"
import ElemChart from "../managsystem/childrens/ElemChart.vue"
import ElemTable from "../communitymobilization/components/ElemTable.vue"

import RequestPage from "../jointly/utils/page"
import Request from "../jointly/utils/request"
import Route from "../communitymobilization/entity/Route"
import CompForm from "../jointly/components/CompForm.vue"
import Utils from "../jointly/utils/utils"
import Publish from "./components/Publish"

export default {
    components: {
        CompContent,
        ElemChart,
        ElemTable,
        CompForm,
        Publish,
    },

    data() {
        return {
            // 菜单名称
            menuName: this.$core.getUrlParam("menuName") || "社区议事",
            route: Route,
            counts: {},
            list: null,
            // 图表属性
            chart_opt: null,
            // 表格属性
            table_columns: [
                {
                    title: "议事类型",
                    key: "t",
                },
                {
                    title: "开展数",
                    key: "v",
                },
            ],

            // 规则表单结构
            rule_forms: [
                {
                    title: "标题",
                    name: "title",
                    type: "input",
                    value: "用户须知",
                },
                {
                    title: "内容",
                    name: "content",
                    type: "textarea",
                    height: "500px",
                    contenteditable: true,
                    value: `
                        <div style="letter-spacing: 1px;color: #333;font-size: 15px;">
                            <div>为保障您的诉求能被及时处理，请认真阅读以下内容:</div>
                            <div style="margin: 5px 0 2px 0">一、诉求登记要求</div>
                            <div>为合理利用社区资源，准确跟进诉求处理请按要求，真实、清晰、完整地填写反映的问题、涉事地址、联系方式等信息。</div>
                            <div>1.遵守中华人民共和国各项法律和规定，诉求内容不含侮辱、色情、人身攻击及反动等违反法律法规、公序良俗等内容的言论;</div>
                            <div>2.按照表单的提示指引，清晰且详细填写各项内容，确保诉求明确，地址等必填信息准确:</div>
                            <div>3.一个表单仅反映一个诉求，如有两个或以上的不同诉求，需选择正确的表单分别填写提交;</div>
                            <div>4.请勿重复反映同一个诉求，已提交的诉求可通过“社区治理——社区议事——意见收集——我的意见”查看处理进度;</div>
                            <div>5.您反映的诉求将根据实际情况分类处理。</div>
                            <div style="margin: 5px 0 2px 0">二、注意事项</div>
                            <div>1.后台有权根据诉求办理规定，归纳修改您诉求内容中的诉求类型、标题等;</div>
                            <div>2.诉求内容办结答复后，可通过“社区治理——社区议事——意见收集——我的意见”对诉求办理情况进行评价。</div>
                            <div>
                                3.为了您的诉求能够得到及时有效处理，请您填写真实姓名和联系方式，如实反映有关情况，并对其真实性负责。我们将严格控制个人信息披露范围，我们在处理您的个人信息时，将依照法律、行政法规等有关规定的权限、程序进行，不超出履行法定职责所必需的范围和限度。
                            </div>
                        </div>
                    `,
                },
            ],

            // 线下表单结构
            offline_forms: null,

            // 统计模块
            statisticsModule: [
                [
                    { t: "本月", k: p => p.ongoingOfMonth + p.archivedOfMonth },
                    { t: "线上开展", k: "onlineOfMonth" },
                    { t: "线下开展", k: "offlineOfMonth" },
                    { t: "进行中", k: "ongoingOfMonth" },
                    { t: "完成归档", k: "archivedOfMonth" },
                ],
                [
                    { t: "本季", k: p => p.ongoingOfQuarter + p.archivedOfQuarter },
                    { t: "线上开展", k: "onlineOfQuarter" },
                    { t: "线下开展", k: "offlineOfQuarter" },
                    { t: "进行中", k: "ongoingOfQuarter" },
                    { t: "完成归档", k: "archivedOfQuarter" },
                ],
                [
                    { t: "本年", k: p => p.ongoingOfYear + p.archivedOfYear },
                    { t: "线上开展", k: "onlineOfYear" },
                    { t: "线下开展", k: "offlineOfYear" },
                    { t: "进行中", k: "ongoingOfYear" },
                    { t: "完成归档", k: "archivedOfYear" },
                ],
                [
                    { t: "累计", k: p => p.totalOngoing + p.totalArchived },
                    { t: "线上开展", k: "totalOnline" },
                    { t: "线下开展", k: "totalOffline" },
                    { t: "进行中", k: "totalOngoing" },
                    { t: "完成归档", k: "totalArchived" },
                ],
            ],
            // 当前日期类型
            ac_date: "all",
            // 统计数据结果
            statisticsResult: {},
            // 当前选择的图表数据
            ac_group: [],
            // 错误提示
            error: null,
        }
    },

    watch: {
        ac_group(v) {
            this.chart_opt = this.getChartOption(v)
        },
        ac_date(v) {
            switch (v) {
                case "all":
                    this.ac_group = this.group_value?.total
                    break
                case "year":
                    this.ac_group = this.group_value?.yearOfTotal
                    break
                case "quarter":
                    this.ac_group = this.group_value?.seasonOfTotal
                    break
                case "month":
                    this.ac_group = this.group_value?.monthOfTotal
                    break
            }
        },
    },

    created() {
        this.reqPage = new RequestPage("/gateway/syinfopublish/api/pc/chamber/listChamberIssueFlowByPage", {
            data: {
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
                notEqualsCode: true,
                channel: 0,
            },
            onChange: res => {
                this.list = res
            },
        })

        // 获取统计数据
        Promise.all([this.getChamberCount(), this.getStatistics()]).catch(res => {
            this.error = "数据获取失败，请稍后再试！"
        })

        setTimeout(() => {
            this.getIssueType()
        })
    },

    methods: {
        async getIssueType() {
            await Request.get("/gateway/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "ISSUE_TYPE",
                appCode: "",
            }).then(res => {
                this.issue_type = res
                this.setOfflineForms(res)
            })
        },

        /**
         * 获取统计数据
         */
        getStatistics() {
            return Request.get("/gateway/syinfopublish/api/pc/chamber/getIssueResult", {
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
            }).then(res => {
                // 字符串数值转为数字类型
                Utils.eachObj(res.issueResult, (k, v) => Number(v))
                // 统计结果
                this.statisticsResult = res.issueResult

                const group_value = {
                    monthOfTotal: [],
                    seasonOfTotal: [],
                    total: [],
                    yearOfTotal: [],
                }

                // 拆分统计项
                Utils.each(res.issueTypeCount, v => {
                    Utils.eachObj(v, (k, kv) => {
                        if (group_value[k]) {
                            group_value[k].push({
                                t: v.issueTypeName,
                                v: kv,
                            })
                        }
                    })
                })

                this.group_value = group_value
                this.ac_group = group_value.total
            })
        },

        /**
         * 获取首页待办数据
         */
        getChamberCount() {
            return Request.get("/gateway/syinfopublish/api/app/chamber/getChamberPendingCount", {
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
            }).then(res => {
                this.counts = res
            })
        },

        getChartOption(d) {
            return d?.length > 0
                ? {
                      tooltip: {
                          trigger: "axis",
                      },
                      color: ["#79baff"],
                      yAxis: {
                          type: "category",
                          data: d.map(v => v.t),
                      },
                      xAxis: {
                          type: "value",
                          scale: true,
                      },
                      series: [
                          {
                              name: this.title,
                              type: "bar",
                              data: d.map(v => v.v || 0),
                          },
                      ],
                  }
                : null
        },

        getFlowName(v) {
            return { 2000: "议题公开", 2001: "议事协商", 2002: "议事表决", 2003: "结果公示", 2004: "执行监督", done: "归档" }[v.flowCode] || "未知"
        },

        onScroll(evt) {
            const t = evt.target
            if (t.scrollHeight - 10 <= t.scrollTop + t.clientHeight) {
                if (!this.bottoming) {
                    this.reqPage.load()
                }
                this.bottoming = true
            } else {
                this.bottoming = false
            }
        },

        onDisplayRule() {
            this.$refs.comp_form.open(parent.vue.loginInfo.userinfo.communityCode)
        },

        setOfflineForms(issueTypes) {
            this.offline_forms = [
                {
                    type: "text",
                    title: "基本信息",
                },
                {
                    type: "input",
                    title: "标题",
                    name: "title",
                    required: true,
                },
                {
                    type: "select",
                    title: "议题类型",
                    name: "issueType",
                    required: true,
                    options: issueTypes.map(v => ({
                        value: v.dictKey,
                        label: v.dictValue,
                    })),
                },
            ]
        },

        onReset() {
            this.reqPage.reset()
        },
    },
}
</script>

<style lang="less" scope>
.agendaroom-index {
    .content {
        display: flex;
        width: 100%;
        height: 100%;

        .online {
            position: relative;
            width: 600px;
            height: 100%;
            overflow-y: auto;
            border-right: 1px solid #e3e3e3;
            flex-shrink: 0;

            > .title {
                position: relative;
                display: flex;
                align-items: flex-end;
                line-height: 25px;
                z-index: 10;

                .btn {
                    cursor: pointer;
                    margin-left: 10px;
                    padding: 5px 15px;
                    line-height: 1;
                    border: 1px solid #e3e3e3;
                    border-radius: 4px;
                    font-size: 12px;
                }
            }

            .tools {
                position: sticky;
                top: 0;
                padding: 10px 30px;
                display: flex;
                justify-content: space-between;
                background: #fff;
                z-index: 10;

                .btn {
                    cursor: pointer;
                    height: 55px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #333;
                    background: #fff;
                    border-radius: 8px;
                    border: 1px solid #f3f3f3;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
                    line-height: 1;

                    &:nth-child(1) {
                        width: calc(30% - 5px);
                        color: #fff;
                        background: #3ed4bd;
                    }

                    &:nth-child(n + 2) {
                        width: calc(70% / 3 - 5px);
                    }

                    .icon {
                        margin-left: 6px;
                        font-size: 15px;
                        font-weight: bold;
                    }

                    .name {
                        font-size: 13px;
                        margin-top: 7px;
                        color: #999;
                    }

                    .value {
                        font-size: 18px;
                        color: rgb(255, 82, 82);
                    }

                    &.column {
                        flex-direction: column;
                    }
                }
            }

            .scroll-view {
                margin: 0 25px;
                display: flex;
                flex-wrap: wrap;
            }

            .agenda-item {
                width: calc(50% - 10px);
                margin: 5px;
                padding: 10px;
                border: 1px solid #f3f3f3;
                border-radius: 6px;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
                display: flex;
                flex-direction: column;

                .title {
                    font-size: 17px;
                    padding: 4px 0;
                    color: #666;
                    font-weight: bold;
                }
                .timer {
                    font-size: 13px;
                    padding: 2px 0;
                    color: #888;
                }
                .tool {
                    width: 100%;
                    padding-top: 8px;
                    overflow: hidden;
                    flex-grow: 1;
                    display: flex;
                    align-items: self-end;
                    justify-content: flex-end;

                    .left-btn {
                        float: left;
                        font-size: 20px;
                        color: #555;
                        margin-right: 10px;
                    }
                    .size {
                        font-size: 16px;
                    }
                    .right-btn {
                        cursor: pointer;
                        float: right;
                        background-color: #2faaf7;
                        color: #ffff;
                        padding: 5px 15px;
                        border-radius: 4px;
                        font-size: 13px;
                    }
                }
            }

            .empty-box {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .img {
                    width: 250px;
                    height: 250px;
                }

                .name {
                    margin-top: 10px;
                    font-size: 15px;
                    color: #888;
                    letter-spacing: 1px;
                }
            }
        }

        .offline {
            flex-grow: 1;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .record {
                cursor: pointer;
                margin: 10px 30px 0 30px;
                padding: 15px 40px;
                border: 1px solid #f3f3f3;
                border-radius: 6px;
                flex-shrink: 0;
            }

            .achievement-box {
                margin: 10px 30px 0 30px;
                border: 1px solid #f3f3f3;
                border-radius: 6px;
                overflow-x: auto;
                flex-shrink: 0;

                .title {
                    margin: 10px 20px 0 20px;
                    line-height: 24px;
                    font-size: 14px;
                }

                .col {
                    display: flex;
                    margin: 10px;

                    .row {
                        display: flex;
                        margin: 0 10px;
                        flex-shrink: 0;

                        .name {
                            color: #888;
                            white-space: nowrap;
                        }

                        .value {
                            color: #333;
                            margin-left: 10px;
                            min-width: 30px;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .tab-box {
                display: flex;
                margin: 20px 30px 0 30px;
                padding: 10px 0;
                flex-shrink: 0;

                .item {
                    cursor: pointer;
                    line-height: 1;
                    padding-bottom: 5px;
                    margin-right: 50px;

                    &.ac {
                        color: #2faaf7;
                        border-bottom: 2px solid #2faaf7;
                    }
                }
            }

            .chart-base {
                display: flex;
                align-items: flex-start;
                flex-shrink: 0;
                padding: 0 30px 20px 30px;
                width: 100%;
                min-width: 800px;
                max-width: 1200px;
                box-sizing: border-box;

                .chart-box {
                    flex: 1;
                    height: 500px;
                    flex-shrink: 0;
                    margin-right: 15px;
                }

                > .table-box {
                    flex: 1;
                    height: 500px;
                }
            }
        }

        .online,
        .offline {
            > .title {
                padding: 20px 30px 5px 30px;
                font-size: 20px;
            }
        }
    }
}

.rule-form {
    .item-box {
        max-width: 600px !important;
    }
}
</style>
