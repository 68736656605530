<template>
    <div class="elem-people-page">
        <div class="input-box" @click="isDisplayPeoplePage = true">
            <p v-if="selected">{{ selected.name }}</p>
            <p v-else class="ph">点击选择人员</p>
        </div>

        <div class="people-page" v-show="isDisplayPeoplePage">
            <div class="people-box">
                <div class="head-box">
                    <div class="title-box">
                        <div class="name">选择参与人</div>
                    </div>
                    <div class="cancel" @click="onClosePeoplePage()">取消</div>
                </div>

                <div class="title">选择参与人类型</div>
                <div class="people-type">
                    <div class="people-item" :class="{ current: configCurrent.peopleType === 0 }" @click="onSelectPeopleType(0)">全体居民</div>
                    <div class="people-item" :class="{ current: configCurrent.peopleType === 1 }" @click="onSelectPeopleType(1)">动员体系沟通组</div>
                    <div class="people-item" :class="{ current: configCurrent.peopleType === 2 }" @click="onSelectPeopleType(2)">指定人</div>
                </div>

                <div class="tree-box" v-if="treeData" v-show="configCurrent.peopleType === 1">
                    <ElemTree ref="elem_tree" :items="treeData" :selected="configCurrent.selected" :value="configCurrent.ids" @on-change="onChangeTree"></ElemTree>
                </div>

                <div class="select-box" v-show="configCurrent.peopleType === 2">
                    <div class="title">输入搜索内容</div>
                    <div class="search-box">
                        <input class="input" v-model="users_search" placeholder="输入用户名称或手机号码进行搜索" />
                        <button class="btn" @click="onSearchUser(users_search)">搜索</button>
                    </div>
                    <div class="title">搜索结果：</div>
                    <div class="list-box">
                        <div class="item" v-for="(item, idx) in users" :key="'list' + idx" @click="onSelectUsers(item)">
                            <p>{{ item.realName }}</p>
                        </div>
                        <div class="empty" v-if="!users || users.length <= 0">暂无对应用户</div>
                    </div>
                    <div class="title">已选用户：</div>
                    <div class="label-box height">
                        <div class="label-item-box" v-for="(item, idx) in configCurrent.value" :key="idx">
                            <p>{{ item.realName }}</p>
                            <div class="delete-btn" @click.stop="onDeleteSelected(idx)">
                                <Icon type="md-close" />
                            </div>
                        </div>
                        <div class="empty" v-show="!configCurrent.value || configCurrent.value.length <= 0">请选择用户</div>
                    </div>
                </div>

                <button class="people-submit-btn" @click="onSubmitPeople">提交</button>
            </div>
        </div>
    </div>
</template>

<script>
import Request from "../../jointly/utils/request"

import ElemTree from "./ElemTree.vue"

export default {
    components: {
        ElemTree,
    },

    data() {
        return {
            selected: null,

            isDisplayPeoplePage: false,
            isDisplayPeopleTree: false,
            configCurrent: {},
            users_search: null,
            users: null,

            treeData: null,

            val: null,
        }
    },

    props: {
        formdata: Object,
        name: String,
        value: {
            type: Object,
            required: false,
        },
    },

    watch: {
        val(v) {
            this.$emit("on-change", {
                tag: "People",
                name: this.name,
                value: v,
            })
        },

        value: {
            handler(v) {
                if (this.val === v) return

                if (!v) {
                    this.selected = null
                    this.$set(this, "configCurrent", { value: [] })
                    return
                }

                v.name = this.getPeopleName(v)

                this.selected = v
                this.$set(this, "configCurrent", v)

                this.val = {
                    userJson: this.getUserConfig(v),
                    userType: { participating: "100", attendance: "101", host: "102", examine: "103" }[this.name],
                }
            },
            immediate: true,
        },
    },

    methods: {
        /**
         * 选择人员类型
         */
        onSelectPeopleType(type) {
            this.$set(this.configCurrent, "peopleType", type)
            this.$set(this.configCurrent, "value", [])

            if (type === 1 && !this.treeData) {
                this.initialChatGroup()
            }
        },

        getPeopleName(v) {
            switch (v.peopleType) {
                case 0:
                    return "已选择全体居民"
                case 1:
                    return "已选择" + v.value.length + "个沟通组"
                case 2:
                    return "已选择" + v.value.length + "个指定人"
            }
        },

        onChangeTree(e) {
            this.$set(this.configCurrent, "selected", e.value)
        },

        /**
         * 选择用户
         */
        onSelectUsers(v) {
            const cfn = this.configCurrent
            const val = cfn.value

            for (let i = 0; i < val.length; i++) {
                if (val[i].custGlobalId === v.custGlobalId) return
            }

            val.push({
                custGlobalId: v.custGlobalId,
                realName: v.realName,
            })

            this.$set(this.configCurrent, "value", val)
        },

        /**
         * 移除用户
         */
        onDeleteSelected(idx) {
            const cfn = this.configCurrent
            const val = cfn.value
            // 移除
            val.splice(idx, 1)
        },

        /**
         * 搜索用户
         */
        onSearchUser(v) {
            if (!v) {
                return (this.users = null)
            }

            Request.get("/gateway/apps/basic/api/base/uc/getByMobileOrNickname", {
                mobileOrNickname: v,
            }).then(res => {
                console.log(res)
                this.users = res
            })
        },

        /**
         * 关闭人员选择弹窗
         */
        onClosePeoplePage() {
            // 清除已选数据
            // this.$set(this, "configCurrent", { value: [] })
            // 关闭页面
            this.isDisplayPeoplePage = false
        },

        /**
         * 初始化沟通组
         */
        initialChatGroup() {
            Request.get("/gateway/chat/app/chat/group/selectChatGroupType", {
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                const list = []

                for (let i = 0, ks = Object.keys(res); i < ks.length; i++) {
                    var k = ks[i]
                    var v = res[k]
                    var childs = []

                    for (let v_i = 0; v_i < v.length; v_i++) {
                        var e = v[v_i]

                        childs.push({
                            id: e.groupId,
                            value: e.groupName,
                            explanation: `${e.userNum}人`,
                            num: e.userNum,
                        })
                    }

                    list.push({
                        id: this.$core.randomString(),
                        value: k,
                        childs: childs,
                    })
                }

                this.userTree = list
                this.treeData = JSON.parse(JSON.stringify(list))
            })
        },

        /**
         * 获取人员配置数据
         */
        getUserConfig(v) {
            var res = {}

            res[["orgCodeList", "groupList", "userList"][v.peopleType]] = v.value

            return JSON.stringify(res)
        },

        /**
         * 提交参与人的选择事件
         */
        onSubmitPeople() {
            const cfn = this.configCurrent

            if (cfn.peopleType === 0) {
                cfn.value = [parent.vue.loginInfo.userinfo.orgCode]
            } else if (cfn.peopleType === 1) {
                var num = 0
                var ids = []
                var value = []

                for (let i = 0, ts = this.configCurrent.selected; i < ts.length; i++) {
                    let v = ts[i]

                    num += v.num

                    value.push({
                        groupId: v.id,
                        groupName: v.value,
                    })

                    ids.push(v.id)
                }

                this.$set(this.configCurrent, "num", num)
                this.$set(this.configCurrent, "ids", ids)
                this.$set(this.configCurrent, "value", value)
            }

            cfn.name = this.getPeopleName(cfn)

            this.selected = cfn

            this.val = {
                userJson: this.getUserConfig(cfn),
                userType: { participating: "100", attendance: "101", host: "102", examine: "103" }[this.name],
            }

            this.isDisplayPeoplePage = false
        },
    },
}
</script>

<style lang="less">
.elem-people-page {
    .input-box {
        cursor: pointer;
        position: relative;
        height: 40px;
        width: 100%;
        padding: 0 10px 0 10px;
        z-index: 10;
        box-sizing: border-box;
        border-radius: 6px;
        transition: all 0.3s ease;
        background: #fff;
        display: flex;
        align-items: center;
        border: 1px solid #e3e3e3;

        .ph {
            color: #d3d3d3;
        }

        &:hover,
        &:focus {
            border-color: #b3b3b3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
    }

    .label-box {
        padding: 7px;
        border: 1px solid #e3e3e3;
        border-radius: 6px;
        transition: all 0.3s ease;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-grow: 1;

        .value {
            padding: 2px;
            color: #333;
            font-size: 14px;
        }

        .empty {
            padding: 2px;
            color: #888;
            font-size: 14px;
        }

        .label-item-box {
            margin: 2px;
            padding: 3px 5px 3px 10px;
            display: flex;
            align-items: center;
            background: #2faaf7;
            border-radius: 4px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: all 0.3s ease;
            color: #fff;
            font-size: 14px;

            &:hover {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
            }

            .delete-btn {
                cursor: pointer;
                padding: 3px 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                font-weight: bold;
                line-height: 1;
            }
        }

        &.height {
            min-height: 100px;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            max-height: 150px;
            overflow-y: auto;
        }

        &.margin {
            margin: 15px 0;
        }
    }
}
.people-page {
    background: rgba(0, 0, 0, 0.6);
    overflow: hidden;
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .people-box {
        margin: 10px;
        background: #fff;
        padding: 0 20px 20px;
        transition: all 0.3s;
        border-radius: 10px;
        width: 500px;

        .head-box {
            width: 100%;
            height: 45px;
            border-bottom: 1px solid #e3e3e3;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > .title-box {
                font-weight: bold;
                display: flex;
                align-items: center;

                .icon-box {
                    margin-right: 10px;
                    font-size: 15px;
                    color: #14b16e;
                }

                .name {
                    color: #14b16e;
                }
            }

            .cancel {
                color: #c3c3c3;
            }
        }

        .title {
            margin-top: 5px;
            font-size: 13px;
            color: #888;
            line-height: 35px;
        }

        .select-box {
            .search-box {
                padding: 5px 5px 5px 10px;
                border: 1px solid #e3e3e3;
                border-radius: 8px;
                display: flex;
                align-items: center;

                .input {
                    flex-grow: 1;
                    font-size: 14px;
                    border: initial;
                }

                .btn {
                    margin-left: 10px;
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #2faaf7;
                    color: #fff;
                    font-size: 12px;
                    width: auto;
                    padding: 7px 20px;
                    border: initial;
                    border-radius: 4px;
                }
            }

            .list-box {
                border: 1px solid #e3e3e3;
                border-radius: 8px;
                max-height: 100px;
                overflow-y: auto;

                .item {
                    cursor: pointer;
                    padding: 10px;
                    border-bottom: 1px solid #f3f3f3;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:last-child {
                        border-bottom: initial;
                    }
                }

                .empty {
                    padding: 10px;
                    text-align: center;
                    font-size: 14px;
                    color: #888;
                }
            }
        }

        .people-type {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .people-item {
                cursor: pointer;
                width: 31%;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #e3e3e3;
                border-radius: 17px;
                font-size: 12px;
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.current {
                    background: #14b16e;
                    color: #fff;
                }
            }
        }

        .people-submit-btn {
            height: 35px;
            background: #14b16e;
            color: #fff;
            border-radius: 7px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            width: 100%;
            border: initial;

            &::after {
                border: initial;
            }
        }

        > .tree-box {
            margin: 10px 0;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            max-height: calc(100vh - 300px);
            overflow-y: auto;
        }

        &.show-box {
            opacity: 1;
        }
    }
}

.tree-page {
    position: absolute;
    top: 0;
    left: calc(50% - 250px);
    width: 500px;
    bottom: 0;
    z-index: 150;
    background: #fff;
    overflow-y: auto;
    border-radius: 16px;

    .head-box {
        position: sticky;
        top: 0;
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #e3e3e3;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;
        background: #fff;
        color: #666;
        font-weight: bold;
    }

    .tree-box {
        > .elem-tree-box {
            padding-bottom: 80px;
        }

        .elem-tree-box .elem-tree-item .row-box.viscosity {
            top: 53px;
        }
    }

    .operate-box {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(transparent, #f9f9f9);
        display: flex;
        justify-content: space-between;
        padding: 15px;
        box-sizing: border-box;
        z-index: 20;

        .item-box {
            width: ~"calc(50% - 10px)";
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            color: #666;
            border-radius: 8px;
            border: 1px solid #e3e3e3;

            &.confirm {
                background: #2db7f5;
                color: #fff;
            }
        }
    }
}
</style>
